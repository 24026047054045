.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.badge-dark{
  background-color: #343a40;
}

.badge-info{
  background-color: #2891b1 !important;
}

.badge-secondary{
  background-color: #6c757d !important;
}

.k-number-badge{
  width: 3rem;
}

h1{
  text-align: center;
}

h2{
  margin-left: 10px;
  margin-top: 10px !important;
}

td{
  padding: 6px !important;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow : hidden;
  white-space: nowrap;
}

.narrow-table{
  max-width: 55em;
  margin: 0 auto;
}

.receiving-table{
  max-width: 70em;
  margin: 0 auto;
}

.receiving-col{
  min-width: 80%;
}

.modal-lg{
  max-width: 70%;
}

.finance-table{
  max-width: 10rem;
  margin: auto;
  border: none;
}

.borderless{
  border: none !important;
}

.profit-format{
  font-weight: bold;
  color: darkgreen;
}

.border-top-thick{
  border-top-width: thick;
}